//Active link search listings
$(function() {
  activeMenu();
});

function activeMenu() {

var url = window.location.pathname,
  urlRegExp = new RegExp(url.replace(/\/$/,'') + "$");
  $('.listings-search .search-filters a').each(function() {
      if(urlRegExp.test(this.href.replace(/\/$/,''))) {
          $(this).addClass('active');
      }
  });
}

//collapse text retail house
jQuery(function ($) {
  var box = $(".retail-house-left-text");
  var minimumHeight = 300; // max height in pixels
  var initialHeight = box.innerHeight();
  // reduce the text if it's longer than 320px
  if (initialHeight > minimumHeight) {
      box.css('height', minimumHeight);
      $(".read-more-button").show();
  }

  SliderReadMore();

  function SliderReadMore() {
    $(".slide-read-more-button-house").on('click', function () {
      // get current height
      var currentHeight = box.innerHeight();

      // get height with auto applied
      var autoHeight = box.css('height', 'auto').innerHeight();

      // reset height and revert to original if current and auto are equal
      var newHeight = (currentHeight | 0) === (autoHeight | 0) ? minimumHeight : autoHeight;

      box.css('height', currentHeight).animate({
        height: (newHeight),
      });

      $(".slide-read-more-button-house").toggle();
    });
  }
});

//collapse text retail house with plot
jQuery(function ($) {
  var box = $(".retail-house-with-plot-left-text");
  var minimumHeight = 290; // max height in pixels
  var initialHeight = box.innerHeight();
  // reduce the text if it's longer than 320px
  if (initialHeight > minimumHeight) {
      box.css('height', minimumHeight);
      $(".read-more-button").show();
  }

  SliderReadMore();

  function SliderReadMore() {
    $(".slide-read-more-button-house-with-plot").on('click', function () {
      // get current height
      var currentHeight = box.innerHeight();

      // get height with auto applied
      var autoHeight = box.css('height', 'auto').innerHeight();

      // reset height and revert to original if current and auto are equal
      var newHeight = (currentHeight | 0) === (autoHeight | 0) ? minimumHeight : autoHeight;

      box.css('height', currentHeight).animate({
        height: (newHeight),
      });

      $(".slide-read-more-button-house-with-plot").toggle();
    });
  }
});

//Location permalink category agency
$(".goToCategory").change( function() {
  if ( this.options[this.selectedIndex].value !== 0 ) {
      location.href = this.options[this.selectedIndex].getAttribute('data-permalink');
  }
});

//restrict number phone form
jQuery(document).ready(function($) {
  $('input[type="tel"]').on('input', function() {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });
});

//restrict number zip form
jQuery(document).ready(function($) {
  $('.zip input[type="text"]').on('input', function() {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
  });
});

$(document).on('click', '#search-filters-button', function($event) {
  $event.preventDefault();
  $('#search-filters').toggleClass('search-filters-active');
});

