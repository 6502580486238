import '@glidejs/glide/dist/glide.min';
import Glide from '@glidejs/glide';

window.addEventListener('load', () => {
  if (document.querySelector('.glide')) {
    const glide = new Glide('.glide', {
      gap: 0,
      type: 'carousel',
      startAt: 0,
      perView: 1,
      swipeThreshold: 5,
    });
    glide.on(['mount.after', 'run'], () => {
      document.getElementById("hero-carousel-numbers-current").innerHTML = glide.index + 1;
      document.getElementById("hero-carousel-numbers-totalslides").innerHTML = glide._c.Html.slides.length;
     });
    glide.mount();
  }

  if (document.querySelector('.glide-home-houses-models')) {
    const glide2 = new Glide('.glide-home-houses-models', {
      type: 'carousel',
      startAt: 0,
      perView: 3,
      gap: 30,
      slidesToScroll: 6,
      slidesToShow: 6,
      breakpoints: {
        1200: {
          perView: 3,
        },
        1024: {
          perView: 2,
        },
        600: {
          perView: 1,
        },
      },
    });
    glide2.mount();
  }

  if (document.querySelector('.glide-home-news')) {
    const glide = new Glide('.glide-home-news', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
    });
    glide.mount();
  }

  if (document.querySelector('.glide-announcing')) {
    const glide7 = new Glide('.glide-announcing', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
    });
    glide7.mount();
  }

  if (document.querySelector('.glide-announcing-plot')) {
    const glide8 = new Glide('.glide-announcing-plot', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      swipeThreshold: 10,
    });
    glide8.mount();
  }

  if (document.querySelector('.glide-houses-models')) {
    const glide6 = new Glide('.glide-houses-models', {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      slidesToScroll: 6,
      slidesToShow: 6,
    });
    glide6.mount();
  }

  if (document.querySelector('.glide-post')) {
    const glide3 = new Glide('.glide-post', {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      slidesToScroll: 6,
      slidesToShow: 6,
    });
    glide3.mount();
  }

  if (document.querySelector('.glide-report')) {
    const glide4 = new Glide('.glide-report', {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      slidesToScroll: 6,
      slidesToShow: 6,
    });
    glide4.mount();
  }

  if (document.querySelector('.glide-mind')) {
    const glide5 = new Glide('.glide-mind', {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      slidesToScroll: 6,
      slidesToShow: 6,
    });
    glide5.mount();
  }

});

$(document).on('show.bs.modal','#Gallery-Modal', function () {
  console.log('open modal');
  $('#Gallery-Modal').on('focus', () => {
    console.log('open modal');
    if (document.querySelector('.glide-house-plan')) {
      console.log('creating slider');
      const glide9 = new Glide('.glide-house-plan', {
        gap: 0,
        type: 'slider',
        startAt: 0,
        perView: 1,
        swipeThreshold: 5,
      });

      glide9.mount();
    }
  });
});

$('.blueprints-modal').on('focus', function() {
  const blueprintSlideElement = $(this).find('.glide-house-blueprints:not(.glide--slider)');

  if (blueprintSlideElement) {
    const glide11 = new Glide(blueprintSlideElement[0], {
      gap: 0,
      type: 'slider',
      startAt: 0,
      perView: 1,
      swipeThreshold: 5,
    });

    glide11.on(['mount.after'], () => {
      // placeArrowsModal('.blueprints-modal-body');
    });

    glide11.mount();
  }
});
